import React from 'react';

const SearchBox = ( { change } ) => {
  return (
    <div className='pa2'>
      <input className='tc pa3 ba b--green bg-lightest-blue' 
        type='search' 
        placeholder='Monster Search'
        onChange = {change} //onChange(event listener) listens for event, communicates with parent here, passes the search input
      />
    </div>
  )
}

export default SearchBox;
import React from 'react';
import Card from './Card';
// import { robots } from './robots'; 

const CardList = ({robots}) => {
  // const cardArray = ;
  // var key = robot[3].id;
  // console.log(key);
  return (
    <div>
      {
        robots.map((robot, i) => {
          return (
            <Card
              key = { i }
              id={ Math.floor(Math.random() * 1333) /1.3 }
              name = {robot.name } 
              email = {robot.email} 
            />
          );
        })
      }
    </div>
  );
}

export default CardList;
import React from 'react';

const Scroll = (props) => {
  return (
    <div style={{ overflowY: 'scroll', border: '1px solid black', height: '800px' }}>
      { props.children }  {//console.info("pass cardList obj here") 
                            }
      {/*console.log(props)*/}
    </div>
  )
}

export default Scroll;
import React, { Component} from 'react';
import CardList from '../components/CardList';
import SearchBox from '../components/SearchBox';
// import { robots } from './robots'; // replaced by fetch request
import Scroll from '../components/Scroll';
import './App.css';

class App extends Component {
  constructor() {
    super()
    this.state = {
      robots: [], 
      searchfield: '',
      flag: false
    }
  }

  // shouldComponentUpdate(nextProps, nextState){
  //   if(this.props.flag !== nextProps.flag) {
    
  //     // fetch('https://jsonplaceholder.typicode.com/users')
  //     // .then(res =>  res.json())
  //     // .then(users => this.setState( {robots: users}));
  //     return true;
  //   } else return false;
  //   // return nextProps.robots !== this.props.robots;
  // }

  // componentDidUpdate(prevProps) {
  //   // Typical usage (don't forget to compare props):
  //   return prevProps.robots !== this.props.robots;
  //   // this.setState( {flag: true});
  // }


  componentDidMount() {
    // console.log("CDM");
    fetch('https://jsonplaceholder.typicode.com/users')
    .then(res =>  res.json())
    .then(users => this.setState( {robots: users}));
    // this.setState( {flag: true});
  }

  // my function
  onSearchChange = (e) => { 
    // use setState instead of this.searchfield = xxx
    this.setState({ searchfield: e.target.value })
    // console.log(filteredBots);
  }

  render() {
    const { searchfield, robots } = this.state;
    const filteredBots = robots.filter(r => {
      return r.name.toLowerCase().includes(searchfield.toLowerCase());
    });
    // doesn't work
    // const length = this.state.robot.filter(r => this.state.robot.length === 0);
    return !robots.length ? 
       <h1 className='tc'>Loading</h1>
       : (
        <div className='tc'>
          <h1 className='f2' style={{"display": "inline", "marginLeft":"6em"}}>MonsterMania</h1>
          <h1 className='f6' style={{"display": "inline"}}> <a  href='https://monster.fatchance.tech' style={{"marginLeft" : "7em"}}> Click for v2</a></h1>
          <SearchBox change = {this.onSearchChange } />
          <Scroll>
            <CardList robots = { filteredBots } />
          </Scroll>  
        </div>
      );
  }
}

export default App;